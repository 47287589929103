import { AxiosError } from "axios";
import { errorToast } from "./toast";
import { ADMIN_ROLE, UNAUTHORIZE_STATUS_CODE } from "./constant";
import { store } from "../store/Store";
import { SetStateAction, Dispatch } from "react";
import { Dispatch as ReduxDispatch } from "@reduxjs/toolkit";
import { ZIndexMaintainer } from "./interface";
import { setToken } from "../store/slice/BaseSlice";

export const handleCatchResponse = (error: AxiosError) => {
    const message = "Something is wrong please try again!";

    for (const key in error) {
        if (key === "request") {
            if (error[key].status === UNAUTHORIZE_STATUS_CODE) {
                localStorage.removeItem("Token");
                window.location.reload();
            }
            const responseMessage = JSON.parse(error[key].response);
            errorToast(responseMessage.message);
            return;
        }
    }
    errorToast(message);
};

export const handleErrorForFetch = async (
    error: AxiosError,
    setError: React.Dispatch<React.SetStateAction<string | null>>,
    dispatch: ReduxDispatch
) => {
    let message = "Something went wrong!";
    for (const key in error) {
        if (key === "request") {
            if (error[key].status === UNAUTHORIZE_STATUS_CODE) {
                dispatch(setToken(null));
                window.location.reload();
            }
            const responseMessage = await JSON.parse(JSON.stringify(error[key].response));
            message = responseMessage.message ?? "Something went wrong!";
        }
    }
    setError(message);
};

export const checkAdminAuth = () => {
    const token = getToken();
    return Boolean(token);
};

export const getToken = (): string | null => {
    return store?.getState()?.base?.token;
};

export const checkIsSuperAdmin = () => {
    const role = store?.getState()?.base.role;
    return role === ADMIN_ROLE.superAdmin;
};

export const serializeQueryParams = <T extends object | string>(payload: T) => {
    if (typeof payload === "object") {
        const queryParams = new URLSearchParams();

        for (const key in payload) {
            queryParams.append(key, payload[key] as string);
        }

        return queryParams.toString();
    }

    const urlParams = new URLSearchParams(payload);

    const params = Object.fromEntries(urlParams);

    return params;
};

export const getImageUrl = (img: string | File): string => {
    if (typeof img === "string") {
        return img;
    } else if (img) {
        return URL.createObjectURL(img);
    }
    return "";
};

export const onDatePickerOpen = (setZIndex: Dispatch<SetStateAction<ZIndexMaintainer>>, fieldName: string) => {
    return () => {
        setZIndex((o) => ({ ...o, [fieldName]: "100" }));
    };
};

export const onDatePickerClose = (setZIndex: Dispatch<SetStateAction<ZIndexMaintainer>>, fieldName: string) => {
    return () => {
        setZIndex((o) => ({ ...o, [fieldName]: undefined }));
    };
};
