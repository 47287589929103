import { createSlice } from "@reduxjs/toolkit";
import { TableDataPayload, TableSliceValue, SetBooleanPayload } from "../../helper/interface";

const initialState: TableSliceValue = {
    loading: false,
    tableData: { page: 1, limit: 10, maxPage: 1, search: null, orderBy: null, tableFor: null, metaFilter: null },
};

export const TableDataSlice = createSlice({
    name: "table",
    initialState,
    reducers: {
        setTableData: (state, action: TableDataPayload) => {
            state.tableData = action.payload;
        },
        setLoading: (state, action: SetBooleanPayload) => {
            state.loading = action.payload;
        },
    },
});

export const { setTableData, setLoading } = TableDataSlice.actions;
export default TableDataSlice.reducer;
