import { combineReducers, configureStore } from "@reduxjs/toolkit";
import BaseSlice from "./slice/BaseSlice";
import TableDataSlice from "./slice/TableDataSlice";
import { persistReducer, persistStore } from "redux-persist";
import storage from "redux-persist/lib/storage";

const persistConfig = {
    key: "root",
    storage,
    whitelist: ["base"],
    blacklist: ["table"],
};

const rootReducer = combineReducers({
    base: BaseSlice,
    table: TableDataSlice,
});

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
    reducer: persistedReducer,
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
            serializableCheck: {
                ignoredActions: ["persist/PERSIST"],
                ignoredPaths: ["register"],
            },
        }),
});

export const persistor = persistStore(store);
